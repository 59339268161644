.app {
  background-color: var(--surface-1);
  color: var(--element-1);
  min-height: 100vh;
  min-width: 100vw;
  transition: all 0.25s ease-in;

  &.error {
    background: var(--error-bg) no-repeat center center fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .wrapper {
    margin: 0 20rem;
  }
}
