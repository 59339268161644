.header--navigation {
  background-color: var(--surface-2);
  transition: all 0.25s ease;
  margin-bottom: 4.7rem;

  &.error {
    background-color: transparent;
  }

  .header--navigation__container {
    padding: 0.71em 0;
    margin: 0 20rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    .header--navigation__brand {
      a {
        color: var(--element-1);
        text-decoration: none;
        transition: all 0.25s ease;
        font-weight: 600;
        font-size: 1.5rem;

        &:focus {
          outline: none;
        }
      }
    }

    .header--navigation__list {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      position: relative;

      &:hover .selected {
        border: none;
      }

      .header--navigation__item {
        font-weight: 400;
        text-align: center;

        &:nth-child(4) {
          margin-right: 1rem;
        }
        .header--navigation__link {
          font-size: 1.1rem;
          padding: 1rem 1rem 1.15rem;
          color: var(--element-2);
          text-decoration: none;
          transition: color 0.25s, font-weight 0.25s ease;

          &:focus {
            outline: none;
          }

          &:hover {
            color: var(--primary);
          }

          &.selected {
            font-size: 1.15rem;
            font-weight: 900;
            color: var(--primary);
            border-bottom: 0.25rem solid var(--primary);
          }
        }
      }

      // .selected-indicator {
      //   height: 0.2em;
      //   width: 3.1em;
      //   background-color: var(--primary);
      //   position: absolute;
      //   left: 0.7em;
      //   bottom: -1.5em;
      //   transition: transform 0.25s ease;
      // }

      .header--color-toggle__container {
        padding-left: 2rem;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        border-left: 0.15rem solid var(--element-2);
      }
    }
  }
}
