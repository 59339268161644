footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: var(--element-3);
  margin-top: 4.7rem;

  .footer-container {
    padding: 1.2em 0;
    margin: 0 20rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    .social-media-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-around;
      width: 25%;

      .social-media-link {
        text-decoration: none;
        color: var(--element-3);

        &:hover {
          color: var(--primary);
        }
      }
    }
  }
}
