@import "./styles/reset.scss";

@import url("https://fonts.googleapis.com/css2?family=Lusitana:wght@400;700&family=Raleway:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$colors: (
  "--element-1": #e6e6e6,
  "--element-2": #202429,
  "--element-3": #2f363e,
  "--surface-1": #4b5765,
  "--surface-2": #fdfbf4,
  "--surface-3": #f5f2e9,
  "primary": #086d74,
);

@mixin color-var($property, $color) {
  #{$property}: map-get($colors, "#{$color}");
  #{$property}: var(--#{$color}, map-get($colors, "#{$color}"));
}

:root {
  &[color-mode="light"] {
    --element-1: #202429;
    --element-2: #2f363e;
    --element-3: #4b5765;
    --surface-1: #fdfbf4;
    --surface-2: #f5f2e9;
    --surface-3: #eae4d3;
    --primary: #086d74;
    --error-bg: url("./images/404_bg_light.jpg");

    & .light--hidden {
      display: none;
    }
  }
  &[color-mode="dark"] {
    --element-1: #fefefe;
    --element-2: #f5f5f5;
    --element-3: #e8e8e8;
    --element-4: #d3d3d3;
    --surface-1: #222831;
    --surface-2: #333f50;
    --surface-3: #3e516c;
    --primary: #ffc190;
    --error-bg: url("./images/404_bg_dark.jpg");

    & .dark--hidden {
      display: none;
    }
  }
}

body {
  margin: 0;
  font-family: "JetBrains Mono", sans-serif;
  // font-family: "Lusitana", serif;
  // font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.color-mode {
  :root[color-mode="light"] & {
    .light--hidden {
      display: none;
    }
  }
  :root[color-mode="dark"] & {
    .dark--hidden {
      display: none;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    padding: 0;

    svg {
      width: 1.55rem;
      height: 1.55rem;
      fill: var(--element-2);
      transition: all 0.25s ease;
    }

    &:hover svg {
      fill: var(--primary);
    }
  }
}

.main--content__container {
  position: absolute;
  top: 8.85rem;

  .main--content__title {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  .main--content__paragraph {
    margin-bottom: 0.5rem;
  }
}

/* ----------- React Transition Group animation classes ----------- */
.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 150ms ease 100ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 100ms ease;
}
